// components/TrainerLogic.js

import { useState, useEffect, useMemo } from 'react';
import { Chess } from 'chess.js';
import LichessExplorer from './lichessExplorer'; // Adjust the path as necessary
import ChessDB from './chessDB'; // Import ChessDB
import ChessEngine from './ChessEngine';
import assert from 'assert';

// Define the total number of moves for the training session
const TOTAL_MOVES = 10;
const mistake_threshold = 30;

const useTrainerLogic = (pgn, playerColor, onCompletion) => { // Accept playerColor
  const [game] = useState(new Chess()); // Keep game mutable; no need to use setGame
  const [moves, setMoves] = useState([]); // List of all predefined moves
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0); // DO NOT DELETE. Screen updates depends on Reflects game.moveNumber
  const [mistakeSquare, setMistakeSquare] = useState(null);
  
  // Replace hintSquare with hintSquares
  const [hintSquares, setHintSquares] = useState([]);

  // New state to store move rankings from ChessDB
  const [moveRankings, setMoveRankings] = useState([]);

  const chessDB = useMemo(() => new ChessDB(), []);
  
  const chessEngine = useMemo(() => new ChessEngine(), []); // Initialize ChessEngine

  // Determine trainer's color
  const trainerColor = playerColor === 'w' ? 'b' : 'w';

  // Instantiate LichessExplorer and ChessDB
  const explorer = useMemo(() => new LichessExplorer({
    variant: 'standard',
    speeds: ['blitz', 'rapid', 'classical'],
    ratings: [1200, 1400, 1600, 1800, 2200, 2500],
    topGames: 0, // We don't need top games, only the moves
    recentGames: 0
  }), [playerColor]);

  // ===========================
  // === Scoring Metrics State ===
  // ===========================

  // Book Moves: Correct book moves in PredefinedMoves
  const [bookMovesCount, setBookMovesCount] = useState(0);
  
  // Non Book Moves: Incorrect moves in PredefinedMoves
  const [nonBookMovesCount, setNonBookMovesCount] = useState(0);
  
  // Best Moves: Best moves made when not in PredefinedMoves
  const [bestMovesCount, setBestMovesCount] = useState(0);
  
  // Great Moves: Moves that are acceptable but not the best when not in PredefinedMoves
  const [greatMovesCount, setGreatMovesCount] = useState(0);
  
  // Mistakes: Bad moves made when not in PredefinedMoves
  const [mistakesCount, setMistakesCount] = useState(0);
  
  // Hints: Number of times the player solicited a hint
  const [hintsCount, setHintsCount] = useState(0);
  
  // Total Centipawn Loss
  const [totalCentipawnLoss, setTotalCentipawnLoss] = useState(0);
  
  // Final Engine Evaluation
  const [finalEngineEvaluation, setFinalEngineEvaluation] = useState(null);
  
  // To track if a hint has been used for the current move
  const [hintUsedForMove, setHintUsedForMove] = useState(new Set());

  useEffect(() => {
    loadPgn(pgn);
  }, [pgn]);

  useEffect(() => {
    if (moves.length > 0) {
      resetGame();
    }
  }, [moves]);

  const getMoveIndex = () => {
    return game.history().length;
  };

  const loadPgn = (pgnString) => {
    try {
      const tempGame = new Chess();
      tempGame.loadPgn(pgnString);
      const fullMoves = tempGame.history({ verbose: true });
      setMoves(fullMoves);
      // Do not setGame here! We want to keep the initial game state

    } catch (error) {
      console.error('Error loading PGN:', error);
      console.error('An exception occurred while loading PGN');
    }
  };

  const resetGame = (pgnString='') => {
    game.reset(); // Reset the Chess game instance
    setCurrentMoveIndex(getMoveIndex());
    setMistakeSquare(null);
    setHintSquares([]); // Reset hintSquares on game reset
    setMoveRankings([]); // Reset move rankings on game reset

    // Reset Scoring Metrics
    setBookMovesCount(0);
    setNonBookMovesCount(0);
    setBestMovesCount(0);
    setGreatMovesCount(0);
    setMistakesCount(0);
    setHintsCount(0);
    setTotalCentipawnLoss(0);
    setFinalEngineEvaluation(null);
    setHintUsedForMove(new Set());

    if (onCompletion) {
      onCompletion(false); // Indicate that the game is not completed
    }

    // After resetting, if it's trainer's turn, make the trainer move
    if (game.turn() === trainerColor && !isGameOver()) {
      handleTrainerMove();
    }
  };

  const handleUserMove = async (move, promotion) => {
    if (isGameOver()) {
      console.log('Info: You have reached the total number of moves!');
      return false;
    }

    if (isWithinPredefinedMoves()) {
      return await handlePredefinedMove(move, promotion);
    } else {
      return await handleChessDBMove(move, promotion);
    }
  };

  const getTotalHalfMoves = () => {
    return playerColor === 'w' ? TOTAL_MOVES * 2 - 1 : TOTAL_MOVES * 2;
  };

  const isGameOver = () => {
    return getMoveIndex() >= getTotalHalfMoves();
  };

  const isWithinPredefinedMoves = () => {
    return getMoveIndex() < moves.length;
  };

  const handlePredefinedMove = async (move, promotion) => {
    const expectedMove = moves[getMoveIndex()];
    if (validatePredefinedMove(move, expectedMove)) {
      // Correct Book Move
      setBookMovesCount(prev => prev + 1);
      const userMoveSuccess = applyMove(move, promotion);
      if (userMoveSuccess) {
        await handleTrainerMove();
      } else {
        throw new Error('Failed to apply user move');
      }
      return userMoveSuccess;
    } else {
      // Incorrect Book Move
      setNonBookMovesCount(prev => prev + 1);
      handleIncorrectMove(move);
      return false;
    }
  };

  const validatePredefinedMove = (userMove, expectedMove) => {
    return userMove.from === expectedMove.from && userMove.to === expectedMove.to;
  };

  // Updated handleChessDBMove to use stored moveRankings
  const handleChessDBMove = async (move, promotion) => {
    try {
      const availableMoves = moveRankings; // Use stored moveRankings instead of querying ChessDB again

      if (!availableMoves || availableMoves.length === 0) {
        console.error('Error: No available moves found for the current position.');
        return false;
      }

      const topMove = availableMoves[0];
      const topScore = topMove.score;

      const userMoveStr = constructMoveString(move, promotion);
      const userMoveData = availableMoves.find(m => m.move === userMoveStr);

      if (!userMoveData) {
        // Bad Move
        setMistakesCount(prev => prev + 1);
        handleIncorrectMove(move);
        return false;
      }

      const scoreDifference = topScore - userMoveData.score;

      if (scoreDifference === 0) {
        // Best Move
        setBestMovesCount(prev => prev + 1);
      } else if (scoreDifference > 0 && scoreDifference <= mistake_threshold) {
        // Great Move
        setGreatMovesCount(prev => prev + 1);
      } else if (scoreDifference > mistake_threshold) {
        // Mistake
        setMistakesCount(prev => prev + 1);
        handleIncorrectMove(move);
        return false;
      }

      // Update Total Centipawn Loss
      setTotalCentipawnLoss(prev => prev + scoreDifference);      

      const userMoveSuccess = applyMove(move, promotion);
      if (userMoveSuccess) {
        await handleTrainerMove();
      }
      return userMoveSuccess;

    } catch (error) {
      console.error('Error validating move with ChessDB:', error);
      console.error('An error occurred while validating your move.');
      return false;
    }
  };

  const sortMovesByScore = (ranking) => {
    return ranking.sort((a, b) => {
      return b.score - a.score; 
    });
  };  

  const updateMoveRankings = async () => {
    try {
      // After applying the trainer move, fetch and store move rankings
      const updatedFEN = game.fen();
      const rankings = await chessDB.getMoves(updatedFEN);
      const sortedRankings = sortMovesByScore(rankings);
      setMoveRankings(sortedRankings);
    } catch (error) {
      console.error('Error fetching moves from ChessDB:', error);
      return [];
    }
  };

  const constructMoveString = (move, promotion) => {
    let moveStr = `${move.from}${move.to}`;
    if (promotion) {
      moveStr += promotion.toLowerCase();
    }
    return moveStr;
  };

  const applyMove = (move, promotion) => {
    const moveResult = game.move({
      from: move.from,
      to: move.to,
      promotion: promotion,
    });

    if (!moveResult) {
      console.error('Invalid move:', move);
      return false;
    }
    setMoveRankings([]); // Reset move rankings after each move
    setCurrentMoveIndex(getMoveIndex()); // Update to reflect the new move number
    setMistakeSquare(null); // Clear any previous mistake
    return true;
  };

  const handleTrainerMove = async () => {
    assert(game.turn() === trainerColor, 'It is not the trainer\'s turn!');

    if (checkGameCompleted()) return;
  
    if (isWithinPredefinedMoves()) { 
      await applyPredefinedTrainerMove();
    } else {
      try {
        await applyExplorerTrainerMove(); // Try using the explorer's move first
      } catch (error) {
        console.warn('Explorer move failed, falling back to stockfish:', error);
        // Fallback to Stockfish in case of failure        
        await applyStockfishTrainerMove();
      }
    }
    await updateMoveRankings();
    setCurrentMoveIndex(getMoveIndex());
    checkGameCompleted();      
  };

  const getStockfishMoves = async (fen) => {
    try {
      // Return the promise directly without awaiting
      return chessEngine.getBestMoves(fen, 5, 15, 5, 2000); // Get top 5 moves with depth 15
    } catch (error) {
      console.error('Error fetching moves from Stockfish:', error);
      return [];
    }
  };

  const applyStockfishTrainerMove = async () => {
    try {
      const currentFEN = game.fen();
      const stockfishMoves = await getStockfishMoves(currentFEN); // Get moves from Stockfish
  
      if (!stockfishMoves || stockfishMoves.length === 0) {
        console.error('Error: No moves found from Stockfish for the current position.');
        return;
      }

      const sortedMoves = sortMovesByScore(stockfishMoves);
  
      // Select the top move after sorting
      const topMove = sortedMoves[0];
      const moveResult = game.move({
        from: topMove.move.substring(0, 2),
        to: topMove.move.substring(2, 4),
        promotion: topMove.promotion || undefined,
      });
  
      if (!moveResult) {
        console.error('Error: Invalid move applied from Stockfish.');
        return;
      }
  
    } catch (error) {
      console.error('Error applying Stockfish trainer move:', error);
    }
  };
  
  const checkGameCompleted = () => {
    if (isGameOver() || game.isCheckmate()) {
      // Capture the final engine evaluation before calling onCompletion
      if (moveRankings && moveRankings.length > 0) {
        setFinalEngineEvaluation(moveRankings[0].score);
      } else {
        setFinalEngineEvaluation(null);
      }
  
      if (onCompletion) {
        onCompletion(true, game.isCheckmate()); // Indicate that the game is completed and whether it's a checkmate
      }
      return true;
    }
    return false;
  };
  

  const applyPredefinedTrainerMove = async () => {
    const trainerMove = moves[getMoveIndex()];
    try {
      await delay(300);
      const moveResult = game.move({
        from: trainerMove.from,
        to: trainerMove.to,
        promotion: trainerMove.promotion,
      });

      if (!moveResult) {
        throw new Error('Error: Invalid Trainer move applied.');
      }
    } catch (error) {
      console.error('Error applying Trainer move:', error);
      console.error('An error occurred while applying Trainer move.');
    }
  };

  const applyExplorerTrainerMove = async () => {
    try {
      const currentFEN = game.fen();
      const probableMoves = await explorer.getNextMoves(currentFEN);
  
      if (!probableMoves || probableMoves.length === 0) {
        throw new Error('Error: No games found for the current position.');
      }
  
      const totalGames = probableMoves.reduce((acc, move) => acc + move.totalGames, 0);
      if (totalGames === 0) {
        throw new Error('Error: No games found for the current position.');
      }
  
      const originalProbs = probableMoves.map(move => (move.totalGames / totalGames) * 100);
      const flattenedProbs = flattenProbabilities(originalProbs, 0.9);
      console.log('Original Probs:', probableMoves.map(move => move.uci+' '+(move.totalGames*100 / totalGames)));      
      console.log('Flattened Probabilities:', flattenedProbs);
      const chosenMove = chooseMove(probableMoves, flattenedProbs);
      console.log('Chosen Move:', chosenMove);
  
      if (!chosenMove) {
        throw new Error('Error: Trainer could not select a move.');
      }
  
      const moveResult = game.move({
        from: chosenMove.uci.substring(0, 2),
        to: chosenMove.uci.substring(2, 4),
        promotion: chosenMove.promotion || undefined,
      });
  
      if (!moveResult) {
        throw new Error('Error: Invalid Trainer move applied.');
      }
  
    } catch (error) {
      console.error('Error fetching Trainer move:', error);
      throw error;;
    }
  };
  
  /**
   * Flattens an array of probabilities using a power transformation.
   *
   * @param {number[]} originalProbs - Array of original probabilities in percentages (e.g., [25, 20, 15, ...]).
   * @param {number} alpha - Flattening parameter (0 < alpha < 1 to flatten).
   * @returns {number[]} - Array of flattened probabilities in percentages, summing to 100%.
   */
  const flattenProbabilities = (originalProbs, alpha) => {
    if (!Array.isArray(originalProbs) || originalProbs.length === 0) {
      throw new Error("originalProbs must be a non-empty array.");
    }
    if (typeof alpha !== 'number' || alpha <= 0) {
      throw new Error("alpha must be a positive number.");
    }

    // Convert original probabilities from percentages to decimals
    const originalDecimals = originalProbs.map(p => p / 100);

    // Apply power transformation: p_i' = p_i^alpha
    const transformed = originalDecimals.map(p => Math.pow(p, alpha));

    // Calculate the sum of transformed probabilities
    const sumTransformed = transformed.reduce((acc, val) => acc + val, 0);

    if (sumTransformed === 0) {
      throw new Error("Sum of transformed probabilities is zero. Check your input probabilities and alpha.");
    }

    // Normalize the transformed probabilities and convert back to percentages
    const flattenedProbs = transformed.map(p => (p / sumTransformed) * 100);

    // Optional: Round to two decimal places for readability
    const flattenedProbsRounded = flattenedProbs.map(p => Math.round(p * 100) / 100);

    return flattenedProbsRounded;
  };

  /**
   * Chooses a move based on the provided probabilities.
   *
   * @param {Object[]} moves - Array of move objects. Each object should have at least a 'uci' property.
   * @param {number[]} probabilities - Array of probabilities corresponding to each move, in percentages.
   * @returns {Object|null} - The chosen move object or null if no move is chosen.
   */
  const chooseMove = (moves, probabilities) => {
    if (moves.length !== probabilities.length) {
      console.error('Moves and probabilities arrays must be of the same length.');
      return null;
    }

    // Convert probabilities to cumulative probabilities
    const cumulativeProbs = [];
    probabilities.reduce((acc, prob, index) => {
      cumulativeProbs[index] = acc + prob;
      return cumulativeProbs[index];
    }, 0);

    // Generate a random number between 0 and 100
    const rand = Math.random() * 100;

    // Find the move where rand <= cumulative probability
    for (let i = 0; i < cumulativeProbs.length; i++) {
      if (rand <= cumulativeProbs[i]) {
        return moves[i];
      }
    }

    // Fallback in case of rounding errors
    return moves[moves.length - 1];
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleIncorrectMove = (move) => {
    console.warn('Incorrect Move: Try again!');
    setMistakeSquare(move.to); // Highlight the incorrect square
    // Remove the mistake indication after 500 ms
    setTimeout(() => setMistakeSquare(null), 500);
  };

  const handleMove = async (move, promotion) => {
    const moveSuccess = await handleUserMove(move, promotion);
    // After user's move, if it's trainer's turn, handle trainer's move
    if (moveSuccess && game.turn() === trainerColor && !isGameOver()) {
      await handleTrainerMove();
    }
    return moveSuccess;
  };

  const showHint = () => {
    const currentIndex = getMoveIndex(); // Adjust for 0-based array index
    if (isWithinPredefinedMoves()) {
      if (!hintUsedForMove.has(currentIndex)) {
        const expectedMove = moves[currentIndex];
        setHintSquares([expectedMove.from]); // Set as array
        setHintsCount(prev => prev + 1);
        setHintUsedForMove(prev => new Set(prev).add(currentIndex));
        // Remove the hint after 2 seconds
        setTimeout(() => setHintSquares([]), 2000);
      }
    } else {
      // Not within predefined moves, use moveRankings for hint
      if (moveRankings && moveRankings.length > 0) {
        // Find all moves within 20 score points from the top move
        const topScore = moveRankings[0].score;
        const validMoves = moveRankings.filter(m => (topScore - m.score) <= mistake_threshold);
        const squares = validMoves.map(m => m.move.substring(0, 2)); // Extract 'from' squares
        if (squares.length > 0) {
          setHintSquares(squares);
          if (!hintUsedForMove.has(currentIndex)) {
            setHintsCount(prev => prev + 1);
            setHintUsedForMove(prev => new Set(prev).add(currentIndex));
          }
          // Remove the hint after 2 seconds
          setTimeout(() => setHintSquares([]), 2000);
        } else {
          console.warn('Hint Unavailable: No hints available for this position.');
        }
      } else {
        console.warn('Hint Unavailable: No hints available for this position.');
      }
    }
  };

  return {
    game,
    moves,
    currentMoveIndex, // Now reflects game.moveNumber
    mistakeSquare,
    hintSquares, // Updated from hintSquare to hintSquares
    handleMove,
    showHint,
    resetGame,
    loadPgn,
    // ======== Scoring Metrics ========
    bookMovesCount,
    nonBookMovesCount,
    bestMovesCount,
    greatMovesCount, // New metric
    mistakesCount,
    hintsCount,
    totalCentipawnLoss,
    finalEngineEvaluation, // New metric
  };
};

export default useTrainerLogic;
