// components/Sidebar.js

import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';

const Sidebar = ({ visible, onClose, onSelectOpening, onOpenPGN }) => {
  if (!visible) return null;

  return (
    <TouchableOpacity
      style={styles.overlay}
      activeOpacity={1}
      onPress={onClose} // Close sidebar when overlay is pressed
    >
      {/* Sidebar Button */}
      <TouchableOpacity
        style={styles.sidebarButton}
        onPress={onClose}
        accessibilityLabel="Close Menu"
        accessibilityHint="Closes the sidebar menu"
      >
        <Text style={styles.sidebarButtonText}>☰</Text>
      </TouchableOpacity>

      {/* Sidebar Content */}
      <TouchableOpacity
        style={styles.sidebar}
        activeOpacity={1}
        onPress={(e) => e.stopPropagation()} // Prevent closing when sidebar is pressed
      >
        {/* Space between sidebar button and menu items */}
        <View style={styles.menuContainer}>
          {/* Menu Options */}
          <TouchableOpacity
            style={styles.menuItem}
            onPress={() => {
              onSelectOpening();
              onClose();
            }}
            accessibilityLabel="Select Opening"
            accessibilityHint="Opens the opening selection screen"
          >
            <Text style={styles.menuText}>Select Opening</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.menuItem}
            onPress={() => {
              onOpenPGN();
              onClose();
            }}
            accessibilityLabel="Open PGN"
            accessibilityHint="Opens the PGN input modal to paste your PGN"
          >
            <Text style={styles.menuText}>Open PGN</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

Sidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectOpening: PropTypes.func.isRequired,
  onOpenPGN: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // Dark semi-transparent overlay
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  sidebarButton: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? 10 : 10,
    left: 20,
    padding: 10,
    backgroundColor: '#374151', // Same as in App.js
    borderRadius: 5,
    zIndex: 2, // Ensure it's on top
  },
  sidebarButtonText: {
    fontSize: 24,
    color: '#fff', // White color for contrast
  },
  sidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 300, // Fixed width of 300px
    height: '100%',
    backgroundColor: '#1f2937', // Dark background for dark mode
    paddingTop: Platform.OS === 'ios' ? 70 : 50, // Adjust padding to account for status bar and button
    paddingHorizontal: 20,
  },
  menuContainer: {
    marginTop: 20, // Add space between the sidebar button and menu items
  },
  menuItem: {
    marginVertical: 15,
  },
  menuText: {
    fontSize: 20,
    color: '#fff', // White text for dark background
  },
});

export default Sidebar;
